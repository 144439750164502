.container {
	:global(.card-header) {
		display: flex;
		justify-content: space-between;
	}

	:global(.expansion-panels-wrap) {
		position: relative;
	}
	:global(.expansion-panel-summary) {
	}
	:global(.delete-bank-account) {
		color: #ff0000;
	}
}
