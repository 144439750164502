.modal {
	:global {
		.content {
			.table-container {
				width: 800px;
			}
		}

		.centered-cell {
			text-align: center;
			white-space: wrap;
		}
	}
}

.drawer {
	:global {
		.content {
			margin: 2rem 3rem;
			width: 350px;
			display: flex;
			flex-direction: column;

			h1 {
				margin-top: 0;
			}

			.claim-data-container {
				margin: 40px 20px;
			}

			.row {
				display: flex;
				justify-content: space-between;
				min-width: 240px;
			}

			.label {
				line-height: normal;
				width: 180px;
			}

			.dark-text {
				font-weight: 500;
			}

			.pdf {
				margin-top: 0.3rem;
				width: 340px;
				height: 500px;
			}

			.button-container {
				margin: 30px auto;
				display: flex;
				flex-direction: column;
				height: 80px;
				justify-content: space-between;
				.button {
					min-width: min-content;
				}
			}

			.divider {
				margin: 0.8rem 0;
			}

			.text-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 30px;
			}

			.need-financing {
				margin-top: 20px;
				margin-bottom: 20px;

				.financed-amount {
					margin-top: 10px;
					font-size: x-large;
					font-weight: bold;
				}
				.caption {
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}

			.status-section {
				display: flex;
				flex-direction: column;
				align-items: center;

				.status {
					margin: 30px auto 0;
				}
			}
		}
	}
}
