.container {
	:global {
		.create-order-button {
			margin-top: 20px;
		}
	}
}

.modal {
	:global {
		.content {
			display: flex;
			flex-direction: column;
			min-width: 500px;
		}
		.text-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 20px 0;
			p {
				margin: 0;
			}
		}
		.financed-amount {
			font-size: 24px;
			font-weight: 800;
		}
	}
}
