.container {
	:global(.header) {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
  
	  h1 {
		display: block;
		width: 100%;
	  }
  
	  :global(.pull-right) {
		float: right;
	  }
	}
  
	:global(.grid-container) {
	  margin: 10px;
	}

	.notification {
		position: fixed;
		top: 50px; 
		left: 90%;
		transform: translateX(-50%);
		background-color: #4caf50;
		color: #fff;
		padding: 10px;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		z-index: 999; 
	  }
  
  }
  