.container {
	.small-text {
	  font-size: 10px;
	  line-height: 10px;
	}
  
	p {
	  margin-bottom: 5px;
	}
  
	.custom-fields-wrap {
	  border-top: 1px solid #e4e9f0;
	  padding-top: 20px;
  
	  .actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	  }
  
	  .custom-fields-list {
		.custom-field {
		  &:first-child {
			margin-top: 20px;
		  }
		}
	  }
	}
  
	.ant-upload {
	  .preview-image {
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		display: block;
		margin-bottom: 10px;
	  }
	}

	
  }