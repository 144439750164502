.container {
	:global {
		.actions-column {
			> div {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-end;

				.MuiButton-root {
					margin: 2px;
					flex-shrink: 0;

				}
			}
		}
	}
}
