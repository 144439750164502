.titleContainer {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
}
.viewMaster {
	top: 0;
}

.claimNumers {
	overflow: auto;
	white-space: normal;
}
