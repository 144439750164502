.container {
	:global {
		.MuiPaper-root {
			margin-bottom: 20px;
		}

		.report-data {
			h3 {
				padding:0;
				margin:0 0 20px 0;
			}

			p {
				margin:0;
			}
		}

		.rt-table {
			.rt-th {
				text-align: right;

				&:first-child {
					text-align: left;
				}
			}
		}
	}
}
