.container {
	:global(.header) {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			display: block;
			width: 100%;
		}

		:global(.pull-right) {
			float: right;
		}
	}

	:global(.grid-container) {
		margin: 10px;
	}

	:global(.number-column) {
		text-align: right;
		margin-right: 12px;
	}
}
