.container {
  h4 {
    color: #666;
    font-size: 12px;
    line-height:18px;
    font-weight: normal;
    margin:0;
    padding:0;
  }

  :global(.disabled) {
    opacity:0.4;
  }
  :global(.top-row) {
    margin-bottom:10px;
  }
}
