$text-color: #707070;
$success-color: #0d553f;
$danger-color: #ff0000;
.container {
	:global {
		padding-top: 20px;
		color: $text-color;
		div,
		p {
			color: $text-color;
		}
		.divider {
			margin-top: 20px;
			margin-bottom: 20px;
			background-color: $text-color;
			height: 25px;
			width: 1px;
		}

		.centered-cell {
			text-align: center;
			white-space: wrap;
		}
		.right-aligned-cell {
			text-align: right;
		}

		.space-top {
			margin-top: 40px;
		}
		.horizontal-space {
			margin-inline: 0.5rem;
		}
		.divider-container {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
		}

		.large-text {
			font-size: 24px;
			font-weight: bolder;
			line-height: normal;
		}

		.accordion {
			&.MuiAccordion-root::before {
				background-color: #ffffff;
			}
			&.MuiAccordion-root {
				margin-bottom: 10px;
				&.Mui-expanded {
					& .MuiAccordionSummary-root {
						border-bottom: 1px solid #bdbdbd;
					}
				}
			}
		}

		.order-summary {
			padding-left: 0;
			height: 80px;

			.label {
				line-height: normal;
			}

			.order-id {
				translate: -45px;
				width: 130px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 80px;
			}

			.centered {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.last-item {
				border-right: none;
			}
			.status {
				position: relative;
				padding: 0;
				margin: 0;
				min-width: 80px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: rotate(-90deg);
				translate: -25px;
				color: white;
				font-weight: bold;
				font-size: smaller;
			}

			.order-details {
				margin-left: 40px;
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: flex-end;
			}
		}

		.order-details {
			flex-direction: column;
			.accordion-details-buttons {
				display: flex;
				justify-content: flex-end;
				width: 100%;
				margin-top: 20px;
			}
			.button {
				min-width: min-content;
				margin-left: 20px;
			}
		}

		.fulfillment-card-container {
			display: flex;
			align-items: center;
			justify-content: center;

			.delivered {
				color: $success-color;
			}
			.remaining {
				color: $danger-color;
			}
		}
		.fulfillment-card {
			display: flex;
			align-items: center;
			margin-right: 5px;
		}

		.qty {
			display: flex;
			flex-direction: column;
		}

		.acceptance-card {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 1rem;

			h6 {
				font-weight: 600;
			}

			.proposal-info-container {
				margin-top: 30px;
			}

			.button-container {
				margin: 30px auto;
			}

			.row {
				display: flex;
				justify-content: space-between;
				min-width: 300px;
			}

			.dark-text {
				font-weight: 500;
			}
		}

		.no-sc-container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 150px;
		}
	}
}
