.modal {
	:global {
		.content {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: center;

			.left-side {
				min-width: 210px;
				flex: 1;
				margin-inline: 1rem;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.field {
					margin-bottom: 10px;
				}
				.col {
					display: flex;
					flex-direction: column;
				}
			}

			.right-side {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
				min-width: 300px;
				.button-container {
					border: 5px dashed #e5e5e5;
					height: 386px;
					width: 300px;
					display: flex;
					justify-content: center;
					align-items: center;
					padding-inline: 1rem;
					border-radius: 1rem;
					margin-bottom: 4px;
				}
				.button-container-with-error {
					border: 5px dashed red !important;
				}
				.delete-container {
					position: absolute;
					height: 386px;
					width: 300px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.delete-container:hover {
					background-color: rgba(214, 214, 214, 0.7);
					transition: all 0.3s ease-in-out;

					.delete-icon {
						opacity: 1;
					}
				}

				.delete-icon {
					opacity: 0;
					transition: all 0.3s ease-in-out;
					color: white;
				}

				.unselected-file-container {
					display: 'flex';
					flex-direction: column;

					.text {
						color: red;
					}
				}
			}
		}

		.buttons {
			margin-top: 1rem;
		}
	}
}
