.container {
	margin-bottom:10px;

	:global(.card-header) {
		display: flex;
		justify-content: space-between;
		margin-bottom:10px;
	}

	h4 {
		margin-top:0;
		padding-top:0;
	}
	:global(.MuiPaper-root) {
		position: relative;
	}
	:global(.PageLoader) {
		position: absolute;
		background: rgba(255,255,255,0.9);
		z-index: 10;
		min-height: 100%;
	}

	:global(.ip-address-wrap) {
		:global(.MuiButtonBase-root) {
			margin-top:15px;
		}
	}
}
