a {
	text-decoration: none !important;
}

a:hover {
	text-decoration: none !important;
}

.text-center {
	text-align: center;
}

.display-flex {
	display: flex;
}

.rdrDateDisplayWrapper {
	background-color: #0c553e !important;
}
.rdrDateDisplayItem
{
	border-radius: 4px !important;
    background-color: rgb(255, 255, 255) !important;
	border: 0px !important;
	input {
		background-color: #0c553e !important;
		color: white !important
	}
}
//MuiDialog-paperFullWidth
//.MuiDialog-paperWidthSm

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
	background-color: #0c553e !important;
	color: white !important
}

.rdrDefinedRangesWrapper {
	width: 150px !important;
}

.rdrStaticRangeLabel {
	padding: 18px 20px !important;
	border-bottom: 0px !important;
}

.rdrStaticRangeLabel:hover {
	background-color: #0c553e !important;
    color: white !important;
}



.formik-upload-button {
	background:none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding:0;
	border:none;
	cursor: pointer;
	//border-width: 1px;
}

.PageLoader {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	min-height: 300px;

	&.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right:0;
		bottom:0;
		min-height: 0px !important;
		background: rgba(255,255,255, 0.8);
		z-index: 100;
		overflow: hidden;
	}
	&.fullscreen {
		position: absolute;
		top: 0;
		left: 0;
		right:0;
		bottom:0;
		min-height: 0px !important;
		background: rgba(255,255,255, 0.8);
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9999;
	}
}

.loading-wrap {
	text-align: center;
	font-weight: bold;
	padding:20px 0;
}

.ReactTable {

	.table-actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.MuiButton-root {
			margin-left: 2.5px
		}
	}
}

