.container {
	margin-top:20px;
	:global(.MuiPaper-root) {
		position: relative;
	}
	:global(.PageLoader) {
		position: absolute;
		background: rgba(255,255,255,0.9);
		z-index: 10;
		min-height: 100%;
	}
	:global(.header) {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			display: block;
			width: 100%;
		}

		:global(.pull-right) {
			float:right;
		}
	}

	:global(.actions) {
		display: flex;
		align-items: center;
		justify-content: center;
		padding:20px 0;
	}
}
