.container {
	&:first-child {
		margin-top:20px;
	}

	:global {
		margin-bottom: 20px;
		background: transparent;
	}
}
