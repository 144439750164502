.container {
	:global(.head-tabs) {
		margin-bottom: 15px;
	}

	:global(.number-column) {
		text-align: right;
		margin-right: 12px;
	}
}
