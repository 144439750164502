.container {
	:global {
		margin-bottom: 20px;
		.cdr-report-checkbox-wrap {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.MuiFormControl-root {
				padding-top: 5px;
			}
		}
		.report-actions {
			width: 100%;
			padding-top: 5px;

			.MuiButton-root {
				margin: 0;
				color: #fff;
				background: #0d553f;
				display: block;
				height: 40px;

				&:last-child {
					width: 100%;
				}
			}
		}

		.no-data {
			margin: 20px 0;
			text-align: center;
			font-weight: bold;
		}
	}
}
