.container {
	:global {
		.MuiPaper-root {
			margin-bottom:20px
		}

		.footer-buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;

			div {
				.MuiButton-root {
					margin-left: 10px;
				}

				.MuiFormControl-root {
					margin-right: 20px;
				}
			}
		}
	}
}
