.container {
	:global(.header) {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			display: block;
			width: 100%;
		}

		:global(.pull-right) {
			float: right;
		}
	}

	:global(.grid-container) {
		margin: 10px;
		padding-top: 10px;
	}

	:global(.archived-row) {
		background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgb(252, 241, 228) 35px, rgb(250, 241, 231) 40px);
	}

	:global(.number-column) {
		text-align: right;
		margin-right: 12px;
	}

	:global(.disclaimer) {
		line-height: 25%;
		font-size: 12px;
		letter-spacing: -0.5px;
		color: rgb(98, 98, 98);
	}
}
