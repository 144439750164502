$text-color: #707070;

.modal {
	:global {
		.content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			overflow-y: scroll;

			.centered-cell {
				text-align: center;
				white-space: wrap;
			}

			.top-section {
				display: flex;
				.proposal-info-container {
					margin-top: 30px;
					margin-inline: 40px;
				}

				.row {
					display: flex;
					justify-content: space-between;
					min-width: 240px;
				}
				.dark-text {
					font-weight: 500;
				}
			}

			.status {
				margin: 2rem auto;
				font-weight: 700;
			}

			.statistics {
				display: flex;
				width: 100%;
				justify-content: center;

				.centered {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 200px;
				}

				.label {
					line-height: normal;
				}

				.divider {
					background-color: $text-color;
					margin-top: 16px;
					height: 25px;
					width: 1px;
				}
			}

			.divider {
				width: 100%;
				margin-top: 3rem;
				margin-bottom: 1rem;
			}

			.space-top {
				margin-top: 1rem;
			}
		}
	}
}

.drawer {
	:global {
		.content {
			margin: 0.5rem 3rem;
			width: 400px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			h1 {
				margin-top: 0;
			}

			.invoice-data-container {
				margin: 20px 20px 0;
			}

			.row {
				display: flex;
				justify-content: space-between;
				min-width: 240px;
			}

			.label {
				line-height: normal;
				width: 180px;
			}

			.dark-text {
				font-weight: 500;
			}

			.pdf {
				margin-top: 0.3rem;
				width: 340px;
				height: 500px;
			}

			.button-container {
				margin: 30px auto;
				.button {
					min-width: min-content;
					margin-left: 20px;
				}
			}

			.status-section {
				display: flex;
				flex-direction: column;
				align-items: center;

				.status {
					margin: 30px auto 0;
				}
			}
		}
	}
}
