.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
}
.rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  user-select: none;
  color: #000000;
  font-size: 0.75rem;
  font-weight: 500;
}
.rt-thead .rt-th {
  text-transform: capitalize;
}
.rt-thead .rt-th,
.rt-thead .rt-td {
  padding: 4px 4px;
  line-height: normal;
  position: relative;
}
.rt-thead .rt-th.-cursor-pointer,
.rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
  padding-right: 16px;
  position: relative;
}
.-sort-desc::after,
.-sort-asc::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  display: block;
}
.-sort-desc::after {
  content: "↓";
}
.-sort-asc::after {
  content: "↑";
}
.rt-thead .rt-th:last-child,
.rt-thead .rt-td:last-child {
  border-right: 0;
}
.rt-thead .rt-resizable-header {
  overflow: visible;
}
.rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.rt-thead .rt-header-pivot:after,
.rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.rt-thead .rt-header-pivot:after {
  border-width: 8px;
  margin-top: -8px;
}
.rt-thead .rt-header-pivot:before {
  border-width: 10px;
  margin-top: -10px;
}
.rt-tbody,
.rt-tfoot {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8125rem;
  font-weight: 400;
}
.rt-tbody .rt-tr-group,
.rt-thead .rt-tr {
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}

.rt-tbody .rt-td:last-child {
  border-right: 0;
}
.rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
  align-items: center;
}
.rt-th,
.rt-td {
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 4px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.rt-th.-hidden,
.rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}
.pagination-bottom {
  margin-top: 8px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.-loading.-active > div {
  transform: translateY(50%);
}
.rt-resizing .rt-th,
.rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}
.rt-pagination-container {
  display: flex;
  align-items: center;
	justify-content: space-between;
	width:100%;
}

.rt-pagination-container span {
	white-space: nowrap;
}
.rt-pagination-container > div {
	width:200px;
}
.rt-pagination-container > div:nth-child(2) { text-align: center }
.rt-pagination-container > div:nth-child(3) { text-align: right }
.rt-pagination-container .MuiInput-root {
	width: 60px !important;
}
.ReactTable .ReactTable .rt-thead {
  color: #000000;
}

.ReactTable:not(.defaultExpanded) .ReactTable .rt-tbody {
  color: #5088e0;
}

.rt-text-right {
  text-align: right;
}

.rt-no-ellipsis {
  overflow: visible;
  white-space: normal;
}

.rt-no-border .rt-tbody .rt-tr-group,
.rt-no-border .rt-thead .rt-tr {
  border-bottom: none;
}

.defaultExpanded .rt-expandable {
  display: none;
}

.defaultExpanded .rt-th:last-child {
  display: none;
}

.defaultExpanded > .rt-table > .rt-tfoot .rt-td:last-child {
  display: none;
}
