.container {
	margin-bottom:10px;
	position: relative;
	:global(.card-header) {
		display: flex;
		justify-content: space-between;
		margin-bottom:10px;
	}

	h4 {
		margin-top:0;
		padding-top:0;
	}
	:global(.MuiPaper-root) {
		position: relative;
	}
	:global(.PageLoader) {
		position: absolute;
		background: rgba(255,255,255,0.9);
		z-index: 10;
		min-height: 100%;
	}

	:global(.sample-table) {
		margin:20px 0 0;
		padding:20px 0 0;
		width:100%;
		border-collapse: collapse;

		th {
			font-weight: bold;
			text-align: center;
		}

		td {
			border-bottom:1px solid #ccc;
			text-align: center;
			padding:5px 10px;
		}
	}
	:global(.legend) {
		padding:10px 0;
		list-style: none;
		display: flex;
		font-size: 8px;
		li {
			padding:0 5px;
		}
	}

	:global(.text-align) {
		text-align: center;

		:global(.MuiFormControl-root) {
			display: inline-block;
			text-align: center;

			p {
				text-align: center;
			}
		}
	}

	:global(.upload-status) {
		text-align: center;
		margin-top:40px;
		font-weight: bold;
	}
}
