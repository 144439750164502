.container {
	h3 {
		font-size: 16px;
	}
	h4 {
		color: #666;
		font-size: 12px;
		line-height:18px;
		font-weight: normal;
		margin:0;
		padding:0;
	}

	:global(.disabled) {
		opacity:0.4;
	}
	:global(.top-row) {
		margin-bottom:10px;
	}
	:global(.MuiPaper-root) {
		position: relative;
	}
	:global(.PageLoader) {
		position: absolute;
		background: rgba(255,255,255,0.9);
		z-index: 10;
		min-height: 100%;
	}
}
