.container {
	:global(.header) {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			display: block;
			width: 100%;
		}

		:global(.pull-right) {
			float:right;
		}
	}

	:global(.MuiPaper-root) {
		position: relative;
	}
	:global(.table-wrapper) {
		position: relative;
	}
	:global(.PageLoader) {
		position: absolute;
		background: rgba(255,255,255,0.9);
		z-index: 1000;
		min-height: 100%;
	}

	:global(.MuiButton-root) {
		float:right;
	}
}
