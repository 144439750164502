.modal {
	:global {
		.content {
			display: flex;
			flex-direction: column;
			min-width: 500px;
		}
		.text-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 20px 0;
			p {
				margin: 0;
			}
		}
		.financed-amount {
			font-size: 24px;
			font-weight: 800;
		}
		.divider {
			margin: 30px 0 20px;
		}
		.tabs-content {
			margin: 20px;
		}

		.qty-input-container {
			display: flex;
			align-items: center;

			.chip {
				margin-left: 10px;
			}
		}
	}
}
