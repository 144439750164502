.container {

	:global {
		.display-flex { display:flex; }
		.routes-list{
			.route-item {
				margin-bottom: 10px;
			}
			.route-item-header {
				&.Mui-expanded {
					background: #0d553f;
					color: #fff;

					.MuiSvgIcon-root {
						color: #fff !important;
					}
				}

			}

			.heading-title {
				font-weight: bold;
				font-size: 16px;
			}
			.heading-subtitle {
				font-size: 12px;
			}

			.MuiAccordionDetails-root {
				padding:20px 20px;

				.heading-title {
					margin-bottom: 20px;
				}

				> * {
					width: 100%;
				}
			}
		}

	}
}
