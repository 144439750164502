.container {
	:global {
		.MuiPaper-root {
			margin-bottom: 20px;
		}

		.report-data {
			h3 {
				padding: 0;
				margin: 0 0 20px 0;
			}

			p {
				margin: 0;
			}

			.hr-full {
				position: absolute;
				left: 0;
				width: 100%;
			}
			
			.space-15 {
				height: 15px;
			}
		}

		.top-most-footer {
			margin-left: 13px;
		}

		.rt-table {
			.rt-th {
				text-align: right;

				&:first-child {
					text-align: left;
				}
			}
		}
	}
}
