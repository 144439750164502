.container {
	margin-bottom: 10px;
	:global(.MuiPaper-root) {
		position: relative;
	}
	:global(.PageLoader) {
		position: absolute;
		background: rgba(255,255,255,0.9);
		z-index: 10;
		min-height: 100%;
	}
	:global(.text-right) {
		text-align: right;
	}
}
